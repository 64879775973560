import { IEntity } from '../components/LiteratureSection/LiteratureToolBar';
import {
  Annotation,
  Bounds,
  PDFPageInfo,
  TokenId,
  annotationFromObject,
  calculateAndGetBounds
} from '../context';
import { PageMeta } from '../network/lib/article/article';
import { IHighlight } from '../network/lib/highlight/highlight';
import { IHSLColor, IUser } from '../network/lib/user/user';

const backgroundColors = [
  '#093145',
  '#0D3D56',
  '#107896',
  '#1496BB',
  '#A3B86C',
  '#829356',
  '#BCA136',
  '#D3B53D',
  '#C2571A',
  '#F26D21',
  '#CD594A',
  '#9A2617',
  '#C02F1D'
];
export const getRandomBackground = () => {
  const randomHue = Math.floor(Math.random() * 15) * 24;
  return `hsl(${randomHue}, 80%, 85%)`;
};

export const getHSLColor = (color?: IHSLColor): string => {
  return color
    ? `hsl(${color.hue}, ${color.saturation * 100}%, ${color.lightness * 100}%)`
    : getRandomBackground();
};

export const lightenDarkenColor = (col: string, amt: number) => {
  let usePound = false;

  if (col[0] == '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const refreshPage = () => window.location.reload();

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    throw new Error('Unable to parse color.');
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
}

export function getBorderWidthFromBounds(bounds: Bounds): number {
  const width = bounds.right - bounds.left;
  const height = bounds.bottom - bounds.top;
  if (width < 100 || height < 100) {
    return 1;
  } else {
    return 3;
  }
}

export const removeUndefined = function (object: object) {
  return JSON.parse(JSON.stringify(object));
};

export const shortenTextWithEllipsis = (text: string, length: number = 300) => {
  const isLonger = text.length > length;
  return `${text.substring(0, length)}${isLonger ? '...' : ''}`;
};

export const breakLongWord = (text: string, length: number = 30) => {
  if (text.includes(' ')) return text;
  return `${text.substring(0, length)}<wbr/>${text.substring(length)}`;
};

export const toTwoDecimalPlace = (num: number) => {
  return num - Math.floor(num) !== 0 ? num.toFixed(2) : String(num);
};

export const pageMetaView = (meta: PageMeta) => {
  const currentPage = meta.page + 1;
  const totalPage = currentPage > meta.n_pages ? currentPage : meta.n_pages;
  const startPage = 1;
  const diff = 2;

  const start = Math.max(currentPage - diff, startPage);
  const end = Math.min(currentPage + diff, totalPage);

  const pageArray: (number | string)[] = [];
  for (let i = start; i <= end; i++) {
    pageArray.push(i);
  }

  if (typeof pageArray[0] === 'number' && pageArray[0] !== startPage) {
    const prepend: (number | string)[] = [startPage];
    if (pageArray[0] > startPage + 1) prepend.push('...');
    pageArray.unshift(...prepend);
  }

  const pageArrayLen = pageArray.length - 1;
  if (typeof pageArray[pageArrayLen] === 'number' && pageArray[pageArrayLen] !== totalPage) {
    const append: (number | string)[] = [totalPage];
    if ((pageArray[pageArrayLen] as number) < totalPage - 1) append.unshift('...');
    pageArray.push(...append);
  }

  return pageArray;
};

export const getEntitySelectionDictionary = (entities: IEntity[]) =>
  entities.reduce((acc: any, entity) => {
    acc[entity.id] = entity.active;
    return acc;
  }, {});

export const getRenderFirstEntities = (entities: IEntity[]) =>
  entities.reduce((acct, entity) => {
    if (entity.render_option === 'first') {
      acct.push(entity.id);
    }
    return acct;
  }, [] as string[]);

export const filterBotHighlightByRenderOption = (
  highlights: IHighlight[],
  entities: IEntity[],
  users: Record<string, IUser>
): IHighlight[] => {
  const renderFirstEntities = getRenderFirstEntities(entities);
  return highlights.filter((paperAnnotation) => {
    const { is_bot } = users[paperAnnotation.user_id];
    if (is_bot) {
      return renderFirstEntities.includes(paperAnnotation.entity_id);
    }
    return true;
  });
};

export const getTextFromAnnotation = (tokens: TokenId[], pages: PDFPageInfo[]) => {
  const sortTokens = sortTokenByIndex(tokens);
  return sortTokens === null
    ? 'Invalid selection'
    : sortTokens.map((token) => pages[token.pageIndex].tokens[token.tokenIndex]?.text).join(' ');
};

interface IFilterReduceOption {
  annotationIds: string[];
  cache: Record<string, boolean>;
}

export const filterDuplicateBotHighlightByRenderOption = (
  pages: PDFPageInfo[],
  pdfAnnotations: Annotation[],
  entities: IEntity[]
): Annotation[] => {
  const renderFirstEntities = getRenderFirstEntities(entities);
  const { annotationIds } = pdfAnnotations.reduce(
    (accumulation, annotation: Annotation) => {
      const { id, entity: { id: entityId } = {}, user: { is_bot } = {} } = annotation;
      const { annotationIds, cache } = accumulation;
      if (is_bot && renderFirstEntities.includes(entityId as string)) {
        const highlightedText = getTextFromAnnotation(annotation.tokens ?? [], pages);
        const key = `${entityId}-${highlightedText}`;
        if (cache[key]) {
          annotationIds.push(id);
        } else {
          cache[key] = true;
        }
      }
      return accumulation;
    },
    { annotationIds: [], cache: {} } as IFilterReduceOption
  );
  return pdfAnnotations.filter((a: Annotation) => !annotationIds.includes(a.id));
};

export const buildPdfAnnotations = (
  pages: PDFPageInfo[],
  highlights: IHighlight[],
  entities: IEntity[],
  users: Record<string, IUser>
): Annotation[] => {
  const annotations = [];
  for (let i = 0; i < highlights.length; i++) {
    const highlight = highlights[i];
    if (!highlight.tokens.some(([pageIdx, tokenIdx]) => tokenIdx > pages[pageIdx].tokens.length)) {
      const { bounds, page } = calculateAndGetBounds(
        { bounds: highlight.bounds, page: highlight.page as number, tokens: highlight.tokens },
        pages
      );
      highlight.bounds = bounds;
      highlight.page = page;
      annotations.push(annotationFromObject(highlight, users[highlight.user_id], entities));
    }
  }
  return annotations;
};

export const filterAnnotationByActiveEntity = (
  pdfAnnotations: Annotation[],
  entities: IEntity[]
): Annotation[] => {
  const entitiesSelectionDict = getEntitySelectionDictionary(entities);
  return pdfAnnotations.filter(
    ({ entity }) => entity.id === '-1' || entitiesSelectionDict[entity.id]
  );
};

export const sortTokenByIndex = (tokens: TokenId[]) => {
  return [...tokens].sort(
    (a, b) => Math.hypot(a.pageIndex, a.tokenIndex) - Math.hypot(b.pageIndex, b.tokenIndex)
  );
};

export const urlPatternValidation = (URL: string) => {
  const regex = new RegExp(
    '(([A-Za-z]{3,9})://)?([-;:&=+$,w]+@{1})?(([-A-Za-z0-9]+.)+[A-Za-z]{2,3})(:d+)?((/[-+~%/.w]+)?/?([&?][-+=&;%@.w]+)?(#[w]+)?)?',
    'gi'
  );
  return regex.test(URL);
};

export const getQueryFromSearchParams = (searchParams: URLSearchParams) => {
  const status = searchParams.getAll('status') || [];
  const decision = searchParams.getAll('decision') || [];
  const assignee = searchParams.getAll('assignee') || [];
  const fromDate = searchParams.get('from_date') || undefined;
  const toDate = searchParams.get('to_date') || undefined;
  return { status, decision, assignee, fromDate, toDate };
};

export const capitalize = (word: string) => {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
};

export const interpretCrontab = (crontab: string, every_n: number) => {
  if (crontab === '0 0 1 * *') return 'Every month';
  if (crontab === '0 0 * * 0' && !every_n) return 'Every week';
  if (crontab === '0 0 * * 0' && every_n === 2) return 'Every 2 weeks';
  if (crontab === '0 0 * * 0' && every_n === 3) return 'Every 3 weeks';
  return 'Custom';
};
const abbreWeek: Record<string, string> = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday'
};
export const interpretCustomCrontab = (crontab: string) => {
  const values = crontab.split(' ');
  if (abbreWeek[values[4]]) {
    return { frequency: 'Weekly', every: abbreWeek[values[4]] };
  }
  if (abbreWeek[values[2]]) {
    const abWk = abbreWeek[values[2]].split('/')[1];
    return { frequency: 'Daily', every: `${abWk} day${abWk === '1' ? '' : 's'}` };
  }
  return;
};
